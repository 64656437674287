import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { RoutingResponse, useRoute } from "../../hooks/useRoute";
import { queriesContext } from "../queries/queries";
import { latLng } from "leaflet";
import { paramsContext } from "../params/params";
import { positionContext } from "../position/position";
import { ContextStateType } from "../../utils/ContextStateType";
import { isGeolocationPositionError } from "../../utils/isGeolocationError";
import { geolocationContext } from "../geolocation/geolocation";
import { qrContext } from "../qr/qr";

const context = createContext<{
	data: RoutingResponse | undefined;
	isLoading: boolean;
	isError: any;
	setProfile: (profile: string) => void;
	routingFrom: ContextStateType<string>;
}>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const { query } = useContext(queriesContext);
	const { params } = useContext(paramsContext);
	const { position } = useContext(positionContext);
	const { data: geolocatedPosition } = useContext(geolocationContext);
	const { data: qr } = useContext(qrContext);

	const [profile, setProfile] = useState("foot-walking");

	const isGeolocationAvailable =
		!!geolocatedPosition && !isGeolocationPositionError(geolocatedPosition);

	const routingFrom = useState(isGeolocationAvailable ? "user-position" : "search-center");

	const [routingFromValue, setRoutingFrom] = routingFrom;

	useEffect(() => {
		if (!isGeolocationAvailable) {
			setRoutingFrom("search-center");
		}
	}, [position]);

	const store = qr?.["stores"].find(({ data: store }) => store["uuid"] === params["store"]);

	const result = useRoute(
		params["mode"] === "route"
			? routingFromValue === "user-position" && geolocatedPosition
				? latLng([
						geolocatedPosition["coords"]["latitude"],
						geolocatedPosition["coords"]["longitude"],
				  ])
				: query?.[1]
			: undefined,
		params["mode"] === "route"
			? store && latLng([store["data"].coords.lat, store["data"].coords.lon])
			: undefined,
		params["mode"] === "route" ? profile : undefined
	);

	return (
		<context.Provider value={{ ...result, setProfile, routingFrom }}>{children}</context.Provider>
	);
};

export { context as routingContext, Provider as RoutingProvider };
