import _ from "lodash";
import { analyticsContext } from "../../layers/analytics/analytics";
import { css } from "../../ui/utils";
import { FC, Fragment, MouseEventHandler, useContext } from "react";
import { geolocationContext } from "../../context/geolocation/geolocation";
import { isGeolocationPositionError } from "../../utils/isGeolocationError";
import { latLng } from "leaflet";
import { Link } from "react-router-dom";
import { paramsContext } from "../../context/params/params";
import { positionContext } from "../../context/position/position";
import { qrContext } from "../../context/qr/qr";
import { queriesContext } from "../../context/queries/queries";
import { ReactComponent as IconBikeWhite } from "./../../assets/bike-white.svg";
import { ReactComponent as IconCarWhite } from "./../../assets/car-white.svg";
import { ReactComponent as IconClose } from "./../../assets/close.svg";
import { ReactComponent as IconGeoloc } from "./../../assets/geoloc.svg";
import { ReactComponent as IconGeolocWhite } from "./../../assets/geoloc-white.svg";
import { ReactComponent as IconMail } from "./../../assets/mail-black.svg";
import { ReactComponent as IconPhone } from "./../../assets/phone-black.svg";
import { ReactComponent as IconRouting } from "./../../assets/routing-black.svg";
import { ReactComponent as IconShop } from "./../../assets/shop.svg";
import { ReactComponent as IconWalkWhite } from "./../../assets/walk-white.svg";
import { ReactComponent as IconWebsite } from "./../../assets/website-black.svg";
import { routingContext } from "../../context/routing/routing";
import { StoreModal } from "../modals";
import { Switch } from "../switch/switch";
import { typo } from "./../../ui/styles";
import { useTranslation } from "react-i18next";
import styles from "./excerpt.module.scss";

const Excerpt: FC<{
	uuid?: string;
	store?: Store;
	onClick?: MouseEventHandler<HTMLDivElement>;
}> = ({ onClick, store: fallbackData, uuid = fallbackData?.["data"]["uuid"] }) => {
	const { t } = useTranslation();

	const triggerAnalytics = useContext(analyticsContext);
	const qrValue = useContext(qrContext);
	const { query } = useContext(queriesContext);
	const { params, setParams } = useContext(paramsContext);
	const store = qrValue["data"]?.["stores"].find(({ data: store }) => store["uuid"] === uuid);
	const {
		data: route,
		setProfile,
		routingFrom: [routingFrom, setRoutingFrom],
	} = useContext(routingContext);
	const {
		geolocatedPosition: [useGeolocation, setUseGeolocation],
	} = useContext(positionContext);
	const { isError } = useContext(geolocationContext);

	const { data: geolocatedPosition } = useContext(geolocationContext);

	const isGeolocationAvailable =
		!!geolocatedPosition && !isGeolocationPositionError(geolocatedPosition);

	if (!store) {
		return null;
	}

	const distance = (
		(route?.["features"]?.[0]?.["properties"]?.["summary"]?.["distance"] ||
			query?.[1]?.distanceTo(latLng(store["data"].coords.lat, store["data"].coords.lon)) ||
			0) / 1000
	).toFixed(2);

	const qr: any = qrValue["data"];
	const storesViewData = qr?.["stores"] || [];

	const storeViewData = storesViewData.find(
		(viewData: any) => viewData["data"]["uuid"] === store["data"]["uuid"]
	)?.["view"];

	return (
		<div className={css(styles["wrapper"])} id={`store#${store["data"].uuid}`} onClick={onClick}>
			{store && params["store"] === store["data"]["uuid"] && params.mode === "view" && (
				<StoreModal
					uuid={store["data"]["uuid"]}
					onClose={() => {
						delete params["mode"];
						setParams({ ...params });
					}}
				/>
			)}

			{params["mode"] === "route" && (
				<div className={css(styles["directions-from"])}>
					<p className={css("small", "gray")}>{t("routing-directions-from-title")}</p>

					<Switch
						className='small'
						value={routingFrom}
						options={[
							isGeolocationAvailable && {
								label: t("widget_location_routing_from_user-position"),
								value: "user-position",
							},
							{ label: t("widget_location_routing_from_search-center"), value: "search-center" },
						].flatMap((i) => (!!i ? [i] : []))}
						onChange={setRoutingFrom}
					/>
				</div>
			)}
			<div
				className={css(
					styles["container"],
					params["store"] === store["data"].uuid && styles["container--selected"],
					params["mode"] === "route" && styles["container--routing"]
				)}>
				<div>
					<div>
						<div className='base pre'>
							<p>{store["data"].name}</p>
							<p style={{ opacity: 0.6 }} className={css(styles["street"], "small")}>
								{[
									_.compact([store["data"].address.line1, store["data"].address.line2]).join("\n"),
									[store["data"].address.zip, store["data"].address.city].join(" "),
								].join("\n")}
							</p>
						</div>
					</div>
					<div className={typo.right}>
						<p className='p'>
							<span>{distance} km </span>
							{!_.isEmpty(storeViewData?.["pin"]?.["small"]) && (
								<Fragment>
									<br />
									<span>
										<img
											src={storeViewData["pin"]["small"]}
											style={{
												height: 17,
												width: 17,
												objectFit: "contain",
												objectPosition: "bottom",
												display: "inline-block",
											}}
											alt='Availability tooltip'
										/>
									</span>
								</Fragment>
							)}
						</p>
					</div>

					{/* {withBrands && !!brands?.length && (
						<div className={styles.controls}>
							<Button
								className={styles.extend}
								onClick={(_) => setIsBrandsModalOpen(true)}>
								<IconBrands className={css(icon.block, icon.normal, icon.spaced)} />
								<IconMore className={css(icon.block, icon.normal)} />
							</Button>
							{isBrandsModalOpen && (
								<BrandsListModal
									onClose={(_) => setIsBrandsModalOpen(false)}
									brands={brands}
								/>
							)}
						</div>
					)} */}
				</div>

				<div className={css(styles["actions-controls"])}>
					{(() => {
						if (params["mode"] !== "route") {
							return (
								<Fragment>
									<div
										className={css("control", "white")}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											triggerAnalytics("STORE:MODAL_OPEN", uuid);
											setParams({ ...params, mode: "view" });
										}}>
										<IconShop />
									</div>

									<div>
										{store["data"].contacts.phone?.international && (
											<Link
												onClick={(e) => {
													e.stopPropagation();
													triggerAnalytics("STORE:PHONE", uuid);
												}}
												to={`tel:${store["data"].contacts.phone.international}`}>
												<div className={css("control", "white")}>
													<IconPhone />
												</div>
											</Link>
										)}

										{store["data"].contacts.email && (
											<Link
												to={`mailto:${store["data"].contacts.email}`}
												onClick={(e) => {
													e.stopPropagation();
													triggerAnalytics("STORE:EMAIL", uuid);
												}}>
												<div className={css("control white")}>
													<IconMail />
												</div>
											</Link>
										)}

										{store["data"]["contacts"]["website"] && (
											<Link
												to={store["data"]["contacts"]["website"]}
												onClick={(e) => {
													e.stopPropagation();
													triggerAnalytics("STORE:WEBSITE", uuid);
												}}>
												<div className={css("control white")}>
													<IconWebsite />
												</div>
											</Link>
										)}

										<div
											className={css("control", "white")}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												triggerAnalytics("STORE:ROUTING", uuid);
												setParams({ ...params, mode: "route" });
											}}>
											<IconRouting />
										</div>
									</div>
								</Fragment>
							);
						}

						return (
							<div>
								<div
									className={css(
										styles["control"],
										styles["control--blue"],
										route?.["metadata"]?.["query"]?.["profile"] === "foot-walking" &&
											styles["control--active"]
									)}
									onClick={(e) => setProfile("foot-walking")}>
									<IconWalkWhite />
								</div>
								<div
									className={css(
										styles["control"],
										route?.["metadata"]?.["query"]?.["profile"] === "cycling-regular" &&
											styles["control--active"]
									)}
									onClick={(e) => setProfile("cycling-regular")}>
									<IconBikeWhite />
								</div>
								<div
									className={css(
										styles["control"],
										route?.["metadata"]?.["query"]?.["profile"] === "driving-car" &&
											styles["control--active"]
									)}
									onClick={(e) => setProfile("driving-car")}>
									<IconCarWhite />
								</div>
							</div>
						);
					})()}
				</div>
			</div>
			{params["mode"] === "route" && (
				<div className={css(styles["actions-controls"], styles["actions-controls--routing"])}>
					<button
						className={css("control", styles["control--red"])}
						onClick={() => {
							delete params["mode"];
							setParams({ ...params });
						}}>
						<IconClose />
					</button>
					<div
						className={css(
							"control",
							!isError && useGeolocation ? "blue" : "white",
							!isError && useGeolocation ? "blue--bg" : "white--bg",
							isError && styles["button--disabled"]
						)}
						onClick={() => setUseGeolocation(!useGeolocation)}>
						{!isError && useGeolocation ? <IconGeolocWhite /> : <IconGeoloc />}
					</div>
				</div>
			)}
		</div>
	);
};

export { Excerpt as StoreExcerpt };
