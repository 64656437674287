import { DetailedHTMLProps, FC, InputHTMLAttributes } from "react";
import styles from "./checkbox.module.scss";
import { motion } from "framer-motion";
import { css } from "../../utils";

type DefaultProps = DetailedHTMLProps<
	InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>;

interface CheckboxProps extends Omit<DefaultProps, "type"> {}

const Checkbox: FC<CheckboxProps> = ({ checked, disabled, ...rest }) => {
	return (
		<label
			className={css(
				styles["container"],
				disabled && styles["container--disabled"]
			)}>
			<input type='checkbox' disabled={disabled} checked={checked} {...rest} />
			<div data-ison={checked || false} className={styles.switch}>
				<motion.div
					layout
					transition={{
						type: "spring",
						stiffness: 700,
						damping: 30,
					}}
				/>
			</div>
		</label>
	);
};

export { Checkbox };
