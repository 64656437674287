import _ from "lodash";
import { FC, Fragment, PropsWithChildren, createContext, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Map } from "../../components/_map/_map";
import { motion } from "framer-motion";
import { Navigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/okeanYs.svg";
import { useEffectOnce, useFetch, useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import axios from "axios";
import styles from "./../../styles/app.module.scss";

const context = createContext<ReturnType<typeof useFetch<Qr>>>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const { search } = useLocation();
	const urlParams = useParams();
	const { qrUuid } = urlParams;

	const { i18n } = useTranslation();

	// For legacy support
	const [searchParams] = useSearchParams();
	const legacy = searchParams.get("target") || searchParams.get("redirect");

	const [history, setHistory] = useLocalStorage<
		{ uuid: string; item: { uuid: string; logo: { landscape: string } } }[]
	>("history", []);

	useEffectOnce(() => {
		Promise.all(
			history.map(async (qr) => {
				try {
					const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/qr/${qr["uuid"]}`);

					if (!data["item"]) {
						return null;
					}

					return {
						qr: data["uuid"],
						item: { uuid: data["item"]["uuid"], logo: data["item"]["logo"] },
					};
				} catch (e) {
					return null;
				}
			})
		).then((data) => {});
	});

	const { data, error } = useFetch<Qr>(
		(qrUuid && `${process.env.REACT_APP_API_HOST}/qr/${qrUuid}`) || undefined
	);

	useEffect(() => {
		setHistory((history) => {
			if (!data || !data.item) {
				return history;
			}

			const isDataInHistory =
				history.map(({ uuid }) => uuid).includes(data.uuid) &&
				_.isEqual(_.get(history, "[0].uuid"), data.uuid);

			if (isDataInHistory) {
				return history;
			}

			return _.uniqBy(
				[
					{
						uuid: data.uuid,
						item: { uuid: data.item.uuid, logo: data.item.logo },
					},
					...history,
				],
				"uuid"
			);
		});
	}, [data, setHistory]);

	const _memData = useMemo(() => data, [data]);
	const _memError = useMemo(() => error, [error]);

	useEffect(() => {
		if (_memData) {
			if (_memData["item"]?.["wording"]?.[i18n.language]) {
				i18n.addResourceBundle(
					i18n.language,
					"translation",
					_memData["item"]["wording"][i18n.language],
					true,
					true
				);
				i18n.changeLanguage();
			}
		}
	}, [_memData, i18n, i18n.language]);

	// Redirect if legacy is set
	if (!qrUuid && !!legacy) {
		return <Navigate to={`/${legacy}${search}`} replace />;
	}

	// Redirect to last history if not set
	const lastHistoryEntry = _.first(history)?.["uuid"];
	if (!qrUuid && lastHistoryEntry) {
		return <Navigate to={`/${lastHistoryEntry}${search}`} replace />;
	}

	// Loading
	if (!!qrUuid && !data && !error) {
		return (
			<Fragment>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className={styles["loader"]}>
					<Logo />
				</motion.div>
				<Map />
			</Fragment>
		);
	}

	return (
		<context.Provider value={{ data: _memData, error: _memError }}>
			{_memData?.["canonical"] && (
				<Helmet>
					<link rel='canonical' href={_memData["canonical"]} />
				</Helmet>
			)}
			{children}
		</context.Provider>
	);
};

export { context as qrContext, Provider as QrProvider };
