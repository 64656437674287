function isGeolocationPositionError(
	error: any
): error is GeolocationPositionError {
	return (
		typeof error === "object" &&
		error !== null &&
		"code" in error &&
		"message" in error &&
		"PERMISSION_DENIED" in error &&
		"POSITION_UNAVAILABLE" in error &&
		"TIMEOUT" in error
	);
}

export { isGeolocationPositionError };
