import {
	FC,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { qrContext } from "../qr/qr";
import { mapContext } from "../map/map";
import { LatLng, latLng } from "leaflet";
import _ from "lodash";
import { paramsContext } from "../params/params";
import { modalsContext } from "../modals/modals";
import axios from "axios";
import SEARCH_STEPS from "../../config/SEARCH_STEPS.json";

type Query = [results: Store[], searchCoords: LatLng, radius: number, location: string];

const context = createContext<{
	query: Query | undefined | null;
	search: (map?: L.Map | null) => void;
	cancel: VoidFunction;
}>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const nonce = useRef<string>();
	const { data: qr } = useContext(qrContext);
	const { params, setParams } = useContext(paramsContext);
	const {
		ref: mapRef,
		getRadius,
		getCenter,
		interactedState: [, setHasInteracted],
	} = useContext(mapContext);
	const [, setModal] = useContext(modalsContext);

	const [query, setQuery] = useState<Query | null>();

	const search = useCallback(
		async (map: L.Map | null = mapRef.current) => {
			if (!map) {
				return;
			}

			const center = getCenter(map);
			const radius = getRadius(map);

			if (_.isUndefined(center) || _.isUndefined(radius)) {
				return;
			}

			if (!qr || !qr["item"]) {
				return;
			}

			setHasInteracted(false);

			const stores = qr["item"]["stores"] || [];
			const area =
				nonce["current"] !== qr["uuid"] ? qr["defaultParams"]?.["search"]?.["area"] : undefined;
			const searchSteps = qr["defaultParams"]?.["search"]?.["radius"] || SEARCH_STEPS;

			nonce["current"] = qr["uuid"]; // reset nonce

			let results: typeof stores = [];
			let searchedRadius: number;

			if (!!area) {
				searchedRadius = area;

				results = stores.filter(
					(store) =>
						center.distanceTo(
							latLng({ lat: store["data"].coords.lat, lng: store["data"].coords.lon })
						) < area
				);
			} else {
				// searchedRadius = Math.min(...searchSteps.map((step) => step.maxStepRadius), radius);
				searchedRadius = 0;

				for (let i = 0; i < searchSteps.length; i++) {
					while (searchedRadius < searchSteps[i].maxStepRadius) {
						const fixedRadius = searchedRadius;

						results = stores.filter(
							(store) =>
								center.distanceTo(
									latLng({ lat: store["data"].coords.lat, lng: store["data"].coords.lon })
								) < fixedRadius
						);

						if (results.length >= searchSteps[i].breakThreshold) break;

						searchedRadius += searchSteps[i].radiusIncrement;
					}

					const fixedRadius = searchedRadius;

					results = stores.filter((store) => {
						return (
							center.distanceTo(
								latLng({ lat: store["data"].coords.lat, lng: store["data"].coords.lon })
							) < fixedRadius
						);
					});

					if (results.length >= searchSteps[i].breakThreshold) break;
				}
			}

			results = results.sort(
				(a, b) =>
					center.distanceTo(latLng({ lat: a["data"].coords.lat, lng: a["data"].coords.lon })) -
					center.distanceTo(latLng({ lat: b["data"].coords.lat, lng: b["data"].coords.lon }))
			);

			const { data: location } = await axios.get<string>(
				`${process.env.REACT_APP_API_HOST}/geocoder/reverse`,
				{
					headers: {
						authorization: `code ${process.env.REACT_APP_PRIVATE_TOKEN}`,
					},
					params: {
						lat: center["lat"],
						lon: center["lng"],
					},
				}
			);

			setParams({
				..._.omit(params),
				lat: center["lat"],
				lon: center["lng"],
				zoom: params["zoom"] || 13,
			});
			setQuery([results, center, searchedRadius, location]);
		},
		[getCenter, getRadius, mapRef, params, qr, setHasInteracted, setParams]
	);

	useEffect(() => {
		if (!!query) {
			if (!query?.[0]?.["length"]) {
				setModal("not-found");
			}
		}
	}, [query, setModal]);

	const cancel = useCallback(() => setQuery(null), []);

	const _memQuery = useMemo(() => query, [query]);

	return (
		<context.Provider value={{ query: _memQuery, cancel, search }}>{children}</context.Provider>
	);
};

export { context as queriesContext, Provider as QueriesProvider };
