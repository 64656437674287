import _ from "lodash";
import {
	FC,
	PropsWithChildren,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useEffectOnce, useLocalStorage } from "usehooks-ts";

const context = createContext<{
	data: GeolocationPosition | undefined;
	isLoading: boolean;
	isError: GeolocationPositionError | undefined;
	start: VoidFunction;
	stop: VoidFunction;
}>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const [_useGPS, setUseGPS] = useLocalStorage("_useGPS", false);

	const [position, setPosition] = useState<
		GeolocationPosition | GeolocationPositionError | boolean
	>(false);

	useEffectOnce(() => {
		if (_useGPS) {
			start();
		}
	});

	const positionId = useRef<number>();

	const start = useCallback(() => {
		stop();
		setUseGPS(true);
		setPosition(true);
		positionId.current = navigator.geolocation.watchPosition(
			(position) => setPosition(position),
			(error) => setPosition(error),
			{ enableHighAccuracy: false, timeout: Infinity, maximumAge: Infinity }
		);
	}, []);

	const stop = useCallback(() => {
		setUseGPS(false);
		setPosition(false);
		if (positionId.current) {
			navigator.geolocation.clearWatch(positionId.current);
		}
	}, []);

	const data = !(position instanceof GeolocationPositionError)
		? !_.isBoolean(position)
			? position
			: undefined
		: undefined;

	const isLoading = _.isBoolean(position) && !!position;

	const isError = position instanceof GeolocationPositionError ? position : undefined;

	useEffect(() => {
		if (isError) {
			setUseGPS(false);
			navigator.permissions.query({ name: "geolocation" }).then((result) => {
				console.warn(result);
			});
		}
	});

	const _memData = useMemo(() => data, [data]);
	const _memIsLoading = useMemo(() => isLoading, [isLoading]);
	const _memIsError = useMemo(() => isError, [isError]);

	return (
		<context.Provider
			value={{
				data: _memData,
				isLoading: _memIsLoading,
				isError: _memIsError,
				start,
				stop,
			}}>
			{children}
		</context.Provider>
	);
};

export { context as geolocationContext, Provider as GeolocationProvider };
