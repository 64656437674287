import { FC, Fragment, useContext } from "react";
import { modalsContext } from "../../../context/modals/modals";
import { ReactComponent as IconHistory } from "./../../../assets/rev_history.svg";
import { ReactComponent as IconSettings } from "./../../../assets/rev_settings.svg";
import { ReactComponent as IconShare } from "./../../../assets/rev_share.svg";
import styles from "./tools.module.scss";
import { useTranslation } from "react-i18next";

const Tools: FC = () => {
	const [, setIsVisible] = useContext(modalsContext);
	const { t } = useTranslation();

	return (
		<Fragment>
			<div className={styles["container"]}>
				<div>
					<div className={styles["item"]} onClick={() => setIsVisible("settings")}>
						<IconSettings />
						<span>{t("nav_settings")}</span>
					</div>
				</div>
				<div>
					<div
						className={styles["item"]}
						onClick={async () => {
							try {
								await navigator.share({ url: window.location.href });
							} catch (error) {
								console.warn(error);
								navigator.clipboard.writeText(window.location.href);
								alert("URL Copied to clipboard");
							}
						}}>
						<IconShare />
					</div>
					<div className={styles["item"]} onClick={() => setIsVisible("history")}>
						<IconHistory />
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export { Tools };
