import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { typo } from "../../ui/styles";
import { css } from "../../ui/utils";
import styles from "./welcome.module.scss";

const Welcome: FC<ModalProps & { banners: Qr["welcomes"] }> = ({ banners, onClose, ...props }) => {
	const { i18n } = useTranslation();

	const currentBanner =
		banners.find(({ languages }) => languages.includes(i18n.language)) ||
		banners.find(({ languages }) => languages.includes(i18n.language.split("-")[0])) ||
		banners.find(({ isFallback }) => isFallback);

	if (!currentBanner) return null;

	if (!currentBanner["images"].length) {
		return null;
	}

	return (
		<Modal
			bg={currentBanner["background"]}
			{...props}
			containerClassName={styles.container}
			className={css(typo.center, styles.modal)}>
			{currentBanner.images.map((image, index) => (
				<img
					key={index}
					className={styles["banner"]}
					src={image["path"]}
					onClick={(e) => {
						switch (image["action"]) {
							case "redirect":
								window.open(image["url"]);
								break;
							case "close":
								onClose?.(e);
								break;
						}
					}}
				/>
			))}
		</Modal>
	);
};

export { Welcome };
