import { css } from "./../../utils";
import {
	DetailedHTMLProps,
	FC,
	MouseEventHandler,
	OptionHTMLAttributes,
	SelectHTMLAttributes,
	useRef,
	useState,
} from "react";
import styles from "./select.module.scss";
import { Paper } from "../Paper/paper";
import { motion, AnimatePresence } from "framer-motion";

type DefaultProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

interface SelectOption {
	label: string;
	default?: boolean;
	value?: OptionHTMLAttributes<HTMLOptionElement>["value"];
	selected?: boolean;
}

interface SelectProps extends Omit<DefaultProps, "onChange"> {
	options: SelectOption[];
	label?: string;
	direction?: "top" | "bottom";
	toggle?: boolean;
	onChange?: (value: OptionHTMLAttributes<HTMLOptionElement>["value"]) => void;
}

const Select: FC<SelectProps> = ({
	options,
	multiple,
	toggle,
	label,
	placeholder,
	value,
	onChange,
	...rest
}) => {
	const selectEl = useRef<HTMLSelectElement>(null);

	const [isOpen, setIsOpen] = useState<boolean>();

	const defaultLabel =
		label ||
		options.find((option) => option.value === value)?.label ||
		options.find((option) => option.value === value)?.label ||
		placeholder ||
		options[0].label;

	const toggleVisibility: MouseEventHandler<HTMLDivElement> = () => setIsOpen(!isOpen);

	const setOption = (option: SelectOption) => {
		if (!onChange) return;
		if (!multiple) return onChange(option.value);

		const valueArray = !Array.isArray(value) ? [value] : value;

		if (toggle)
			return onChange(
				valueArray.includes(option.value)
					? valueArray.filter((i) => i !== option.value)
					: [...valueArray, option.value]
			);

		return onChange(valueArray.includes(option.value) ? valueArray : [...valueArray, option.value]);
	};

	return (
		<label className={styles.container}>
			{/* Options */}
			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial={{
							background: "rgba(33, 44, 53, 0)",
						}}
						animate={{ background: "rgba(33, 44, 53, 0.3882352941)" }}
						exit={{ background: "rgba(33, 44, 53, 0)" }}
						className={css(styles.options)}
						onClick={() => setIsOpen(false)}>
						<motion.div
							initial={{ opacity: 0, translateY: 10 }}
							animate={{ opacity: 1, translateY: 0 }}
							exit={{ opacity: 0, translateY: 10 }}
							transition={{ duration: 0.2, type: "tween", ease: "easeOut" }}
							className={css(styles.translator)}>
							<Paper className={css(styles.list)}>
								{options.map((option, index) => (
									<div key={index} onClick={(_) => setOption(option)}>
										<Paper
											className={css(
												styles.option,
												Array.isArray(value)
													? value.includes(option.value) && styles["option--selected"]
													: value === option.value && styles["option--selected"]
											)}>
											{option.label}
										</Paper>
									</div>
								))}
							</Paper>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			{/* Default Element */}
			<div className={styles.label} onClick={toggleVisibility}>
				{defaultLabel}
			</div>

			{/* Legacy HTML Element */}
			<select
				ref={selectEl}
				multiple={multiple}
				className={styles.input}
				defaultValue={value}
				{...rest}>
				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</label>
	);
};

export { Select };
