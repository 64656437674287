import { DetailedHTMLProps, FC, PropsWithChildren } from "react";
import { css } from "../../utils";
import styles from "./button.module.scss";

type DefaultProps = DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

interface ButtonProps extends DefaultProps, PropsWithChildren {
	variant?: "default" | "danger" | "alert" | "light";
	ui?: "button" | "text";
}

const Button: FC<ButtonProps> = ({
	children,
	variant = "default",
	ui = "button",
	className,
	...rest
}) => {
	return (
		<button
			className={css(
				styles["container"],
				styles[`container--${variant}`],
				styles[`container--${ui}`],
				className
			)}
			{...rest}>
			{children}
		</button>
	);
};

export { Button };
