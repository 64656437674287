import useSWR from "swr";
import axios from "axios";
import { LatLng } from "leaflet";
import _ from "lodash";

export interface RoutingResponse {
	bbox: [number, number, number, number];
	features: any;
	metadata: any;
	type:
		| "Point"
		| "MultiPoint"
		| "LineString"
		| "MultiLineString"
		| "Polygon"
		| "MultiPolygon"
		| "GeometryCollection"
		| "Feature"
		| "FeatureCollection";
}

function useRoute(
	start?: LatLng,
	end?: LatLng,
	profile: string = "foot-walking"
) {
	const { data, error } = useSWR(
		_.some([start, end, profile], _.isNil)
			? null
			: JSON.stringify([start, end, profile]),
		() =>
			axios
				.get<RoutingResponse>(
					`${process.env.REACT_APP_API_HOST}/geocoder/directions`,
					{
						headers: {
							authorization: `code ${process.env.REACT_APP_PRIVATE_TOKEN}`,
						},
						params: {
							start: start && `${start.lng},${start.lat}`,
							end: end && `${end.lng},${end.lat}`,
							profile,
						},
					}
				)
				.then(({ data }) => data)
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,
	};
}

export { useRoute };
