import _ from "lodash";
import { analyticsContext } from "../../../layers/analytics/analytics";
import { css } from "../../../ui/utils";
import { FC, useContext, useEffect, useRef } from "react";
import { paramsContext } from "../../../context/params/params";
import { queriesContext } from "../../../context/queries/queries";
import { StoreExcerpt } from "../../store/excerpt";
import { useTranslation } from "react-i18next";
import styles from "./asideList.module.scss";

const AsideList: FC = () => {
	const triggerAnalytics = useContext(analyticsContext);
	const elRef = useRef<HTMLDivElement>(null);
	const { query } = useContext(queriesContext);
	const { params, setParams } = useContext(paramsContext);
	const { t } = useTranslation();

	useEffect(() => {
		if (params["store"]) {
			elRef.current?.scrollTo({
				top: document.getElementById(`store#${params["store"]}`)?.offsetTop,
				behavior: "smooth",
			});
		}
	}, [params]);

	if (_.isEmpty(query?.[0])) {
		return (
			<div className={css(styles["fallback"])}>
				<p className={css("gray", "center", "small")}>{t("list_no-results")}</p>
			</div>
		);
	}

	return (
		<div ref={elRef} className={css(styles["list"], styles["scroll"])}>
			{query?.[0].map((store, index) => (
				<StoreExcerpt
					store={store}
					key={index}
					onClick={(e) => {
						triggerAnalytics("STORE:SELECTION", store["data"].uuid);
						setParams({ ...params, store: store["data"].uuid });
					}}
				/>
			))}
		</div>
	);
};

export { AsideList };
