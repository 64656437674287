import { css } from "../../../ui/utils/css";
import { FC } from "react";
import { Item } from "../item/item";
import { LocationTextInput } from "../../input/textInput";
import styles from "./location.module.scss";
import { Legend } from "../../legend/legend";

const Location: FC = () => {
	return (
		<div className={styles["location-wrapper"]}>
			<div className={styles["location-controls"]}>
				<LocationTextInput className={css(styles["control"])} />
			</div>

			<div className={styles["map-info"]}>
				<Item />
				<Legend />
			</div>
		</div>
	);
};

export { Location };
