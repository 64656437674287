import _ from "lodash";

const removeFalsy = (value: Record<any, any>) =>
	Object.keys(value).reduce((acc: { [key: string]: string | number }, key) => {
		if (_.isString(key)) {
			if (!_.isNil(value[key])) {
				acc[key] = value[key];
			}
		}

		return acc;
	}, {});

export { removeFalsy };
