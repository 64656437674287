import _ from "lodash";
import "allotment/dist/style.css";
import { Allotment, AllotmentHandle } from "allotment";
import { AsideList } from "./asideList/asideList";
import { css } from "../../ui/utils";
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { geolocationContext } from "../../context/geolocation/geolocation";
import { isGeolocationPositionError } from "../../utils/isGeolocationError";
import { Location } from "./location/location";
import { LocationTextInput } from "../input/textInput";
import { Map } from "../_map/_map";
import { mapContext } from "../../context/map/map";
import { modalsContext } from "../../context/modals/modals";
import { NoResultsModal, WelcomeModal } from "../modals";
import { paramsContext } from "../../context/params/params";
import { qrContext } from "../../context/qr/qr";
import { Refresh } from "./refresh/refresh";
import { routingContext } from "../../context/routing/routing";
import { StoreExcerpt } from "../store/excerpt";
import { Switch } from "../switch/switch";
import { Tools } from "./tools/tools";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../utils/useWindowSize";
import { Widget } from "./widget/widget";
import styles from "./../../styles/app.module.scss";

const ViewPort: FC = () => {
	const { t } = useTranslation();

	const [nonce, setNonce] = useState("");
	const { height } = useWindowSize();
	const matches = useMediaQuery("(min-width: 768px)");

	const { params } = useContext(paramsContext);

	const currentQr = useContext(qrContext);
	const { data: qr } = currentQr;

	const currentMap = useContext(mapContext);
	const [modal, setModal] = useContext(modalsContext);

	const {
		routingFrom: [routingFrom, setRoutingFrom],
	} = useContext(routingContext);

	const ref = useRef<AllotmentHandle>(null);

	useEffect(() => {
		ref.current?.reset();
	}, [matches]);

	const { data: geolocatedPosition } = useContext(geolocationContext);

	const isGeolocationAvailable =
		!!geolocatedPosition && !isGeolocationPositionError(geolocatedPosition);

	if (_.isUndefined(qr)) {
		return <Map />;
	}

	return (
		<Fragment>
			{(() => {
				if (!!qr.welcomes?.length && qr.uuid !== nonce) {
					return (
						<WelcomeModal
							align={"bottom"}
							banners={qr.welcomes}
							onClose={() => setNonce(qr.uuid)}
						/>
					);
				} else if (modal === "not-found") {
					return <NoResultsModal onClose={() => setModal("")} />;
				}
			})()}
			<Allotment
				key={!matches ? "vertical" : "horizontal"}
				ref={ref}
				vertical={!matches}
				separator={true}
				className={styles["main"]}
				onDragEnd={(e) => {
					currentMap.ref.current?.invalidateSize();
				}}
				onChange={(e) => {
					currentMap.ref.current?.invalidateSize();
				}}>
				{matches && (
					<Allotment.Pane
						minSize={390}
						maxSize={390}
						className={css(styles["aside"], styles["aside--desktop"])}>
						<Widget>
							{params["mode"] === "route" ? (
								<div>
									<p className={css("base", "gray")}>{t("widget_location_routing-title")}</p>

									<Switch
										value={routingFrom}
										options={[
											isGeolocationAvailable && {
												label: t("widget_location_routing_from_user-position"),
												value: "user-position",
											},
											{
												label: t("widget_location_routing_from_search-center"),
												value: "search-center",
											},
										].flatMap((i) => (!!i ? [i] : []))}
										onChange={setRoutingFrom}
									/>
								</div>
							) : (
								<div>
									<p className={css("h6 center uppercase")}>{t("widget_location_input-title")}</p>
									<LocationTextInput />
								</div>
							)}
						</Widget>
						<div className={styles["center"]}>
							{(() => {
								if (params["mode"] !== "route") {
									return <AsideList />;
								}

								return (
									<div className={styles["floating"]}>
										<StoreExcerpt uuid={params["store"]} />
									</div>
								);
							})()}
						</div>
						<Tools />
					</Allotment.Pane>
				)}
				<Allotment.Pane className={css(styles["canvas"])}>
					<Location />

					{params["mode"] !== "route" && !!currentMap["interactedState"][0] && (
						<Fragment>
							<Refresh />
							<div className={styles["pointer"]} />
						</Fragment>
					)}

					<Map />

					{!matches && params["mode"] === "route" && (
						<div className={styles["map--bottom"]}>
							<StoreExcerpt uuid={params["store"]} />
						</div>
					)}
				</Allotment.Pane>
				{!matches && params["mode"] !== "route" && (
					<Allotment.Pane
						minSize={200}
						preferredSize={height * 0.3}
						maxSize={height * 0.8}
						className={css(styles["aside"], styles["aside--mobile"])}>
						<div className={styles["draggable"]}></div>
						<div className={styles["center"]}>
							<AsideList />
						</div>
						<Tools />
					</Allotment.Pane>
				)}
			</Allotment>
		</Fragment>
	);
};

export { ViewPort };
