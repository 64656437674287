import { useState } from "react";

import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";

interface WindowSize {
	width: number;
	height: number;
}

export function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const handleSize = () => {
		setWindowSize((prev) => {
			if (
				prev.width !== window.innerWidth ||
				prev.height !== window.innerHeight
			) {
				return {
					width: window.innerWidth,
					height: window.innerHeight,
				};
			}

			return prev;
		});
	};

	useEventListener("resize", handleSize);

	// Set size at the first client-side load
	useIsomorphicLayoutEffect(() => {
		handleSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return windowSize;
}
