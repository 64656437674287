import { FC } from "react";
import styles from "./switch.module.scss";
import { css } from "../../ui/utils";

const Switch: FC<{
	className?: string;
	value?: string;
	options?: {
		label: string;
		value: string;
	}[];
	onChange?: (value: string) => void;
}> = ({ className, options = [], value, onChange }) => {
	return (
		<ul className={css(styles["container"], className)}>
			{options.map((option, index) => (
				<li
					key={index}
					onClick={() => {
						onChange?.(option.value);
					}}
					className={css(styles["option"], option.value === value && styles["option--active"])}>
					{option.label}
				</li>
			))}
		</ul>
	);
};

export { Switch };
