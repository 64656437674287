import _ from "lodash";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { LogoLandscape } from "../../logo/landscape";
import { LogoSquare } from "../../logo/square";
import { qrContext } from "../../../context/qr/qr";
import styles from "./item.module.scss";

const Item: FC = () => {
	const { data: qr } = useContext(qrContext);

	if (_.isUndefined(qr)) {
		return null;
	}

	if (_.isUndefined(qr.item)) {
		return null;
	}

	const labels = qr?.["item"]?.["labels"];

	return (
		<div className={styles["target"]}>
			<div className={styles["info"]}>
				<div className={styles["logo"]}>
					<div>
						<Link to={qr.item.website} target='_blank'>
							<LogoLandscape uuid={qr.item["uuid"]} logo={qr["item"]["logo"]["landscape"]} />
						</Link>
					</div>
				</div>
				{/* Shop count */}
				{/* {((count = query?.[0].length) =>
					!!count && (
						<div className={styles["counter"]}>
							<div>
								<div>
									<span className={css(typo.small, typo.gray)}>
										{t("app.foundIn")}
									</span>
									<span>
										<strong>{count}</strong>
									</span>
									<span className={css(typo.small, typo.gray)}>
										{(() => {
											if (count < 2) {
												return t("app.store");
											}

											return t("app.stores");
										})()}
									</span>
								</div>
							</div>
						</div>
					))()} */}
			</div>
			{!!labels?.length && (
				<div className={styles["labels"]}>
					{labels.map(
						(label, index) =>
							label["logo"]?.["square"] && (
								<div key={index} className={styles["label"]}>
									{label["website"] ? (
										<Link to={label["website"]}>
											<LogoSquare uuid={label["uuid"]} logo={label["logo"]["square"]} />
										</Link>
									) : (
										<LogoSquare uuid={label["uuid"]} logo={label["logo"]["square"]} />
									)}
								</div>
							)
					)}
				</div>
			)}
		</div>
	);
};

export { Item };
