import { DetailedHTMLProps, FC, PropsWithChildren, useState } from "react";
import { css } from "../../utils";
import { Paper } from "../Paper/paper";
import styles from "./accordion.module.scss";
import { ReactComponent as IconCaret } from "./../../../assets/caret.svg";
import { icon } from "./../../styles";
import { motion } from "framer-motion";

type DefaultProps = DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

interface AccordionProps extends DefaultProps, PropsWithChildren {
	heading: JSX.Element;
}

const Accordion: FC<AccordionProps> = ({
	children,
	heading,
	className,
	...rest
}) => {
	const [isOpen, setIsOpen] = useState<boolean>();

	const content = {
		open: { height: "auto" },
		closed: { height: 0 },
	};

	const caret = {
		open: { rotate: -90 },
		closed: { rotate: 0 },
	};

	const toggle = () => setIsOpen(!isOpen);

	return (
		<Paper
			className={css(styles.container, className)}
			onClick={toggle}
			{...rest}>
			<div className={styles.heading}>
				{heading}
				<motion.div
					initial='closed'
					animate={isOpen ? "open" : "closed"}
					variants={caret}>
					<IconCaret className={icon.normal} />
				</motion.div>
			</div>
			<motion.div
				className={styles.content}
				initial='closed'
				animate={isOpen ? "open" : "closed"}
				variants={content}>
				{children}
			</motion.div>
		</Paper>
	);
};

export { Accordion };
