import {
	DetailedHTMLProps,
	FC,
	PropsWithChildren,
	TextareaHTMLAttributes,
} from "react";
import { css } from "../../utils";
import styles from "./textArea.module.scss";

type DefaultProps = DetailedHTMLProps<
	TextareaHTMLAttributes<HTMLTextAreaElement>,
	HTMLTextAreaElement
>;

export interface TextAreaProps
	extends Omit<DefaultProps, "type" | "onSubmit">,
		PropsWithChildren {
	options?: string[];
	onSubmit?: (
		e: React.MouseEvent<HTMLInputElement, MouseEvent>,
		value: string
	) => void;
}

const TextArea: FC<TextAreaProps> = ({
	children,
	value,
	options,
	className,
	onSubmit,
	...rest
}) => {
	return (
		<label className={css(styles.label, className)}>
			<textarea value={value} {...rest} />
		</label>
	);
};

export { TextArea };
