import _ from "lodash";
import { css } from "../../ui/utils";
import { FC, useContext } from "react";
import { icon } from "../../ui/styles";
import { Link } from "react-router-dom";
import { LogoLandscape } from "../logo/landscape";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { Paper } from "../../ui/components/Paper/paper";
import { qrContext } from "../../context/qr/qr";
import { ReactComponent as IconOkeanYs } from "./../../assets/logo-extended.svg";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import styles from "./../disclaimers/disclaimer.module.scss";
import styles1 from "./history.module.scss";

const History: FC<ModalProps> = (props) => {
	const [history, setHistory] = useLocalStorage<
		{ uuid: string; item: { uuid: string; logo: { landscape: string } } }[]
	>("history", []);
	const { data: qr } = useContext(qrContext);

	const { t } = useTranslation();

	const disclaimerContent = !!history["length"]
		? t("modal_history_message")
		: t("modal_history_message-history-empty");

	return (
		<Modal className={"center"} {...props}>
			<IconOkeanYs className={css(icon.block, icon.hbig)} />

			<h1>{t("modal_history_title")}</h1>

			{disclaimerContent && (
				<Paper
					className={css(
						styles["disclaimer"],
						styles["disclaimer--alert"],
						"center",
						"small",
						"gray"
					)}
					dangerouslySetInnerHTML={{
						__html: disclaimerContent,
					}}
				/>
			)}

			{!!history?.length && (
				<section style={{ margin: "1rem 0 0" }} className={"left"}>
					<div className={styles1["history"]}>
						{_.uniqBy(history, "item.uuid").map((entry, index) => (
							<Link to={`/${entry["uuid"]}`} key={index}>
								<LogoLandscape
									uuid={entry["uuid"]}
									logo={entry["item"]["logo"]["landscape"]}
									onClose={
										qr?.["uuid"] !== entry["uuid"]
											? (e) => {
													e.preventDefault();
													e.stopPropagation();
													setHistory(history.filter((h) => h["uuid"] !== entry["uuid"]));
											  }
											: undefined
									}
								/>
							</Link>
						))}
					</div>
				</section>
			)}
		</Modal>
	);
};

export { History };
