import _ from "lodash";
import { analyticsContext } from "../../layers/analytics/analytics";
import { AnimatePresence, motion } from "framer-motion";
import { css } from "../../ui/utils";
import { FC, Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LogoLandscape } from "../logo/landscape";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { paramsContext } from "../../context/params/params";
import { qrContext } from "../../context/qr/qr";
import { ReactComponent as IconMail } from "./../../assets/mail.svg";
import { ReactComponent as IconPhone } from "./../../assets/phone.svg";
import { ReactComponent as IconRouting } from "./../../assets/routing.svg";
import { ReactComponent as IconShare } from "./../../assets/rev_share.svg";
import { ReactComponent as IconWebsite } from "./../../assets/website.svg";
import { typo } from "../../ui/styles";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import styles from "./store.module.scss";

const Store: FC<ModalProps & { uuid: string }> = ({ onClose, uuid }) => {
	const { data: qr } = useContext(qrContext);
	const store = qr?.["stores"].find(({ data: store }) => store["uuid"] === uuid);
	const triggerAnalytics = useContext(analyticsContext);
	const { params, setParams } = useContext(paramsContext);

	const { t } = useTranslation();

	const [alert, setAlert] = useState<{ timeout: NodeJS.Timeout; message: string }>();

	if (!store) {
		return null;
	}

	const labelBrands = store["items"];

	const qrData = qr;
	const storesViewData = qrData?.["stores"] || [];
	const storeViewData = storesViewData.find(
		(viewData: any) => viewData["data"]["uuid"] === store["data"]["uuid"]
	)?.["view"];

	const structured = [
		...(store["data"]["structured"] || []),
		...(storeViewData?.["structured"] || []),
	];

	return (
		<Modal align={"bottom"} onClose={onClose}>
			<div className={css(styles["modal"])}>
				<div>
					{!!store["data"].images.length && (
						<Fragment>
							<Slider dots={false} slidesToShow={1} slidesToScroll={1}>
								{store["data"].images.map((image, index) => (
									<div key={index}>
										<div>
											<img src={image} style={{ width: "100%" }} />
										</div>
									</div>
								))}
							</Slider>
						</Fragment>
					)}

					<h1>{store["data"].name || "loading"}</h1>

					<div>
						<div className={"flex controls"}>
							{store["data"].contacts.phone?.international && (
								<Link
									to={`tel:${store["data"].contacts.phone.international}`}
									onClick={(e) => {
										e.stopPropagation();
										triggerAnalytics("STORE:PHONE", uuid);
									}}>
									<div className={css("control lightblack")}>
										<IconPhone />
									</div>
								</Link>
							)}

							{store["data"].contacts.email && (
								<Link
									to={`mailto:${store["data"].contacts.email}`}
									onClick={(e) => {
										e.stopPropagation();
										triggerAnalytics("STORE:EMAIL", uuid);
									}}>
									<div className={css("control lightblack")}>
										<IconMail />
									</div>
								</Link>
							)}

							{store["data"]["contacts"]["website"] && (
								<Link
									to={store["data"]["contacts"]["website"]}
									onClick={(e) => {
										e.stopPropagation();
										triggerAnalytics("STORE:WEBSITE", uuid);
									}}>
									<div className={css("control lightblack")}>
										<IconWebsite />
									</div>
								</Link>
							)}

							<div
								className={css("control lightblack")}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									triggerAnalytics("STORE:ROUTING", uuid);
									setParams({ ...params, mode: "route" });
								}}>
								<IconRouting />
							</div>

							<div
								className={css("control lightblack")}
								onClick={async (e) => {
									e.stopPropagation();

									triggerAnalytics("STORE:SHARE", uuid);

									try {
										await navigator.share({ url: window.location.href });
									} catch (err) {
										navigator.clipboard.writeText(window.location.href);
										setAlert((alert) => {
											if (alert) {
												clearTimeout(alert["timeout"]);
											}

											return {
												timeout: setTimeout(() => {
													setAlert(undefined);
												}, 3000),
												message: t("modal_store_share-message"),
											};
										});
									}
								}}>
								<IconShare />
							</div>
						</div>
					</div>

					{structured.map((entry, index) => (
						<p key={index} className='pre'>
							{entry["label"] && (
								<Fragment>
									<span className='small gray'>{entry["label"]}</span>
									<br />
								</Fragment>
							)}
							{entry["body"]}
						</p>
					))}

					{store["data"].address && (
						<p className='pre'>
							<span className={css(typo["gray"])}>
								{[
									_.compact([store["data"].address.line1, store["data"].address.line2]).join("\n"),
									[store["data"].address.zip, store["data"].address.city].join(" "),
								].join("\n")}
							</span>
						</p>
					)}

					{store["data"].contacts.phone && (
						<p style={{ marginBottom: 0 }}>
							{store["data"].contacts.phone?.["international"] &&
								store["data"]["contacts"]["phone"]["national"] && (
									<Link
										className={typo.reset}
										to={`tel:${store["data"].contacts.phone.international}`}
										onClick={(e) => {
											e.stopPropagation();
											triggerAnalytics("STORE:PHONE", uuid);
										}}>
										<span>
											{t("modal_store_tel")} {store["data"].contacts.phone.national}
										</span>
									</Link>
								)}
						</p>
					)}

					{store["data"].contacts.email && (
						<p style={{ marginTop: 0, marginBottom: 0 }}>
							{store["data"].contacts.email && (
								<Link
									className={typo.reset}
									to={`mailto:${store["data"].contacts.email}`}
									onClick={(e) => {
										e.stopPropagation();
										triggerAnalytics("STORE:EMAIL", uuid);
									}}>
									{store["data"].contacts.email}
								</Link>
							)}
						</p>
					)}

					{store["data"]["contacts"]["website"] && (
						<p style={{ marginTop: 0, marginBottom: 0 }}>
							{store["data"]["contacts"]["website"] && (
								<Link
									className={typo.reset}
									to={store["data"]["contacts"]["website"]}
									onClick={(e) => {
										e.stopPropagation();
										triggerAnalytics("STORE:WEBSITE", uuid);
									}}>
									{store["data"]["contacts"]["website"]}
								</Link>
							)}
						</p>
					)}
				</div>

				<div>
					<AnimatePresence>
						{alert && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className='rounded theme-blue pvertical phorizontal fit-container mtop no-interaction'>
								{alert["message"]}
							</motion.div>
						)}
					</AnimatePresence>
				</div>

				{qr?.["item"]["type"] === "label" && !!labelBrands?.["length"] && (
					<div id={styles["brands"]}>
						<p className='blue'>{t("modal_store_brands")}</p>
						<div className={styles["grid"]}>
							{labelBrands.map((brand, index) => (
								<Link to={`/${brand["defaultQr"]}`} key={index}>
									<LogoLandscape uuid={brand["uuid"]} logo={brand["logo"]["landscape"]} />
								</Link>
							))}
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export { Store };
