import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { ContextStateType } from "../../utils/ContextStateType";
import { paramsContext } from "../params/params";
import { geolocationContext } from "../geolocation/geolocation";
import DEFAULTS from "../../config/DEFAULTS.json";

const context = createContext<{
	position:
		| {
				lat: number;
				lon: number;
				zoom: number | undefined;
		  }
		| undefined;
	geolocatedPosition: ContextStateType<boolean>;
}>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const { params } = useContext(paramsContext);
	const { data: geolocation, start } = useContext(geolocationContext);

	const position = {
		lat: params["lat"] || DEFAULTS.POSITION["lat"],
		lon: params["lon"] || DEFAULTS.POSITION["lon"],
		// zoom: params["zoom"] || DEFAULTS.POSITION["zoom"],
		zoom: undefined,
	};

	const geolocatedPosition = useState(false);
	const [useGeolocation] = geolocatedPosition;

	useEffect(() => {
		if (useGeolocation && _.isUndefined(geolocation)) {
			start();
		}
	}, [useGeolocation]);

	return (
		<context.Provider
			value={{
				position,
				geolocatedPosition,
			}}>
			{children}
		</context.Provider>
	);
};

export { context as positionContext, Provider as PositionProvider };
