import _ from "lodash";
import { createPortal } from "react-dom";
import { css } from "../../utils";
import { DetailedHTMLProps, FC, PropsWithChildren, useEffect, useState } from "react";
import { icon } from "./../../styles";
import { Paper } from "../Paper/paper";
import { ReactComponent as IconClose } from "./../../../assets/close-modal.svg";
import styles from "./modal.module.scss";

type DefaultProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface ModalProps extends DefaultProps, PropsWithChildren {
	onClose?: React.MouseEventHandler<HTMLElement>;
	onCloseContent?: JSX.Element;
	align?: "top" | "center" | "bottom";
	containerClassName?: string;
	bg?: string;
}

const Modal: FC<ModalProps> = ({
	align = "top",
	children,
	onClose,
	onCloseContent = <IconClose className={css(icon.block, icon.normal)} />,
	className,
	bg,
	containerClassName,
	...rest
}) => {
	const [wrapperElement, setWrapperElement] = useState(() => {
		const el = document.createElement("div");
		el.className = styles.portal;

		if (!_.isEmpty(bg)) {
			el.style.backgroundColor = `#${bg}`;
		}

		el.setAttribute("data-body-scroll-lock-ignore", "true");
		return el;
	});

	useEffect(() => {
		document.body.appendChild(wrapperElement);
		document.body.style.overflow = "";

		return () => {
			document.body.removeChild(wrapperElement);
			document.body.style.overflow = "hidden";
		};
	}, [wrapperElement]);

	return createPortal(
		<div
			className={css(styles.container, styles[`container--${align}`], containerClassName)}
			{...rest}>
			{/* Content */}
			<Paper className={css(styles.content, className)}>
				<div>{children}</div>
				{/* Close Button */}
				{onClose && (
					<Paper className={css(styles.controls)}>
						<Paper className={css(styles.close)} onClick={onClose}>
							{onCloseContent}
						</Paper>
					</Paper>
				)}
			</Paper>
		</div>,
		wrapperElement
	);
};

export { Modal };
