import _ from "lodash";
import { ContextStateType } from "../../utils/ContextStateType";
import { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import {
	ContactModal,
	HistoryModal,
	NoResultsModal,
	SettingsModal,
	StoreModal,
	TargetModal,
} from "../../components/modals";
import { paramsContext } from "../params/params";
import { qrContext } from "../qr/qr";

const context = createContext<ContextStateType<string>>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const state = useState("");
	const [current, setCurrent] = state;
	const { data: qr, error: qrError } = useContext(qrContext);

	const _memState = useMemo(() => state, [state]);

	return (
		<context.Provider value={_memState}>
			{children}

			{(() => {
				switch (current) {
					case "settings":
						return <SettingsModal align='bottom' onClose={() => setCurrent("")} />;
					case "contacts":
						return <ContactModal align='bottom' onClose={() => setCurrent("settings")} />;
					case "history":
						return <HistoryModal align='bottom' onClose={() => setCurrent("")} />;
				}

				if (_.isUndefined(qr) || !!qrError) {
					return <TargetModal align='bottom' />;
				}

				return null;
			})()}
		</context.Provider>
	);
};

export { context as modalsContext, Provider as ModalProvider };
