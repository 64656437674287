import L from "leaflet";
import {
	FC,
	MutableRefObject,
	PropsWithChildren,
	createContext,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { ContextStateType } from "../../utils/ContextStateType";

const context = createContext<{
	ref: MutableRefObject<L.Map | null>;
	getRadius: (map?: L.Map | null) => number | undefined;
	getCenter: (map?: L.Map | null) => L.LatLng | undefined;
	interactedState: ContextStateType<boolean>;
}>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const ref = useRef<L.Map>(null);

	const interactedState = useState(false);

	const getCenter = useCallback((map: L.Map | null = ref.current) => {
		if (!map) return;

		return map.getCenter();
	}, []);

	const getRadius = useCallback((map: L.Map | null = ref.current) => {
		if (!map) return;

		const center = map.getCenter();
		const bounds = map.getBounds();
		const centerEast = L.latLng(center.lat, bounds.getEast());
		const centerWest = L.latLng(center.lat, bounds.getWest());

		const eastDist = center.distanceTo(centerEast);
		const westDist = center.distanceTo(centerWest);

		const mapRadius = Math.max(eastDist, westDist);

		return mapRadius;
	}, []);

	const _memRef = useMemo(() => ref, [ref]);

	return (
		<context.Provider value={{ ref: _memRef, getRadius, getCenter, interactedState }}>
			{children}
		</context.Provider>
	);
};

export { context as mapContext, Provider as MapProvider };
