import { FC, OptionHTMLAttributes } from "react";
import { Select } from "../../../ui";
import { css } from "../../../ui/utils";
import styles from "./select.module.scss";
import LANGUAGES from "./../../../config/LANGUAGES.json";
import { useTranslation } from "react-i18next";

const Language: FC<{
	value?: string;
	onChange?: (value: OptionHTMLAttributes<HTMLOptionElement>["value"]) => void;
}> = ({ value, onChange }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.select}>
			<p className={css("left", "small", "gray")}>{t("modal_settings_language_title")}</p>
			<Select
				placeholder={t("modal_settings_language_placeholder") || undefined}
				value={value}
				options={LANGUAGES.map((language, index) => {
					return { value: language.code, label: language.label };
				})}
				onChange={onChange}
			/>
		</div>
	);
};

export { Language };
