import { motion } from "framer-motion";
import { FC } from "react";
import data from "./../../config/LEGEND.json";
import { useTranslation } from "react-i18next";
import styles from "./legend.module.scss";
import icon_info from "./../../assets/info.svg";
import { css } from "../../ui/utils";
import { useToggle } from "usehooks-ts";

export const Legend: FC = () => {
	const [isOpen, toggleOpen] = useToggle(false);

	const { i18n } = useTranslation();

	if (!data?.["length"]) {
		return null;
	}

	return (
		<div className={styles["wrapper"]}>
			<motion.div
				initial={{ width: 42 }}
				animate={isOpen ? { width: 260 } : { width: 42 }}
				className={css(styles["container"])}>
				<motion.div
					initial={{ borderRadius: "21px 21px 21px 21px" }}
					animate={isOpen ? { borderRadius: "" } : { borderRadius: "21px 21px 21px 21px" }}
					className={css(styles["padding"], "rounded")}>
					<img
						onClick={toggleOpen}
						className={styles["trigger"]}
						src={icon_info}
						alt='Open the legend'
					/>
					<motion.div
						className={styles["accordion"]}
						initial={{ height: 0, whiteSpace: "pre" }}
						animate={isOpen ? { height: "auto" } : { height: 0, whiteSpace: "pre" }}>
						<ul className={styles["list"]}>
							{data.map((data, index) => {
								const language = i18n["language"].split("-")[0];
								const caption =
									language in data["translations"]
										? data["translations"][language as keyof (typeof data)["translations"]]
										: data["translations"]["en"];

								if (!data["img"]) {
									return null;
								}

								return (
									<li key={index}>
										<img
											className={styles["pin"]}
											src={`/assets/legend/icons/${data["img"]}`}
											alt='Legend pin'
										/>
										<div>
											<p className='small' dangerouslySetInnerHTML={{ __html: caption }} />
										</div>
									</li>
								);
							})}
						</ul>
					</motion.div>
				</motion.div>
			</motion.div>
		</div>
	);
};
