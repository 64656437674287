import { DetailedHTMLProps, PropsWithChildren, forwardRef, useState } from "react";
import { css } from "../../utils";
import { Paper } from "../Paper/paper";
import styles from "./textinput.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useElementSize } from "usehooks-ts";

type DefaultProps = DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>;

export interface TextInputProps extends Omit<DefaultProps, "type" | "onSubmit">, PropsWithChildren {
	type?: "text" | "email";
	direction?: "up" | "down";
	options?: JSX.Element[];
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{ direction = "up", children, value, options, className, type = "text", ...rest },
		forwarderRef
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const [ref, { width }] = useElementSize<HTMLLabelElement>();

		const fragments = [
			<AnimatePresence key={0}>
				{/* Autocomplete options */}
				{isFocused && !!options?.length && (
					<div
						className={css(styles["options"], styles[`options--${direction}`])}
						style={{ width }}>
						<Paper className={styles["list"]}>
							{(direction === "down" ? options : [...options].reverse()).map((option) => option)}
						</Paper>
					</div>
				)}
			</AnimatePresence>,
			<label key={1} ref={ref} className={css(styles["label"], className)}>
				{children}
				<input
					ref={forwarderRef}
					onFocus={(e) => {
						e.target.select();
						setIsFocused(true);
						rest["onChange"]?.({ ...e, target: { ...e.target, value: "" } });
					}}
					onBlur={(_) => setIsFocused(false)}
					type={type}
					value={value}
					{...rest}
				/>
			</label>,
		];

		return (
			<div
				className={css(
					styles["wrapper"],
					styles[`wrapper--${direction}`],
					isFocused && !!options?.length && styles["wrapper--open"]
				)}>
				<AnimatePresence>
					{/* Background clearfix */}
					{isFocused && !!options?.length && (
						<motion.div
							initial={{
								background: "rgba(33, 44, 53, 0)",
							}}
							animate={{ background: "rgba(33, 44, 53, 0.3882352941)" }}
							exit={{ background: "rgba(33, 44, 53, 0)" }}
							className={styles.clearfix}
						/>
					)}
				</AnimatePresence>

				{direction === "down" ? [...fragments].reverse() : fragments}
			</div>
		);
	}
);

export { TextInput };
