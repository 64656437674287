import { FC, PropsWithChildren, createContext, useMemo } from "react";
import { useEffectOnce, useLocalStorage } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const context = createContext<string>(undefined!);

const Provider: FC<PropsWithChildren> = ({ children }) => {
	const [searchParams] = useSearchParams();
	const urlSession = searchParams.get("sid");
	const [session, setSession] = useLocalStorage("session", urlSession || uuidv4());
	useEffectOnce(() => setSession(urlSession || session));
	const _memSession = useMemo(() => session, [session]);
	return <context.Provider value={_memSession}>{children}</context.Provider>;
};

export { context as sessionContext, Provider as SessionProvider };
