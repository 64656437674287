import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./refresh.module.scss";
import { queriesContext } from "../../../context/queries/queries";

const Refresh: FC = () => {
	const { search } = useContext(queriesContext);
	const { t } = useTranslation();

	return (
		<div className={styles["refresh"]}>
			<button onClick={() => search()}>{t("map_search-here")}</button>
		</div>
	);
};

export { Refresh };
