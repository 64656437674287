import _ from "lodash";
import "leaflet/dist/leaflet.css";
import { analyticsContext } from "../../layers/analytics/analytics";
import { Circle, MapContainer, Marker, TileLayer, useMapEvents, GeoJSON } from "react-leaflet";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { geolocationContext } from "../../context/geolocation/geolocation";
import { icon, latLng } from "leaflet";
import { mapContext } from "../../context/map/map";
import { paramsContext } from "../../context/params/params";
import { positionContext } from "../../context/position/position";
import { qrContext } from "../../context/qr/qr";
import { queriesContext } from "../../context/queries/queries";
import { routingContext } from "../../context/routing/routing";
import activePin from "./../../components/pin/selected.svg";
import centerPin from "./../../components/pin/position.svg";
import DEFAULTS from "../../config/DEFAULTS.json";
import geolocationPin from "./../../components/pin/geolocation.svg";
import idlePin from "./../../components/pin/default.svg";
import styles from "./../../styles/app.module.scss";
import { useMediaQuery } from "usehooks-ts";

const MapDragListener: FC = () => {
	const mapValue = useContext(mapContext);
	const positionValue = useContext(positionContext);
	const setHasInteracted = mapValue?.["interactedState"][1];
	const setUseGeolocation = positionValue?.["geolocatedPosition"][1];

	useMapEvents({
		dragstart: (e) => {
			setUseGeolocation(false);
			setHasInteracted(true);
		},
		dragend: (e) => {
			setUseGeolocation(false);
		},
	});

	return null;
};

const Map: FC = () => {
	const triggerAnalytics = useContext(analyticsContext);
	const positionValue = useContext(positionContext);
	const qrValue = useContext(qrContext);
	const queryValue = useContext(queriesContext);
	const mapValue = useContext(mapContext);
	const paramsValue = useContext(paramsContext);
	const geolocatedValue = useContext(geolocationContext);
	const routeValue = useContext(routingContext);
	const matches = useMediaQuery("(min-width: 768px)");

	const { lat, lon, zoom } = positionValue?.["position"] || DEFAULTS.POSITION;

	useEffect(() => {
		if (positionValue?.["geolocatedPosition"][0]) {
			if (geolocatedValue?.["data"]) {
				mapValue?.["ref"].current?.panTo([
					geolocatedValue?.["data"].coords.latitude,
					geolocatedValue?.["data"].coords.longitude,
				]);
			}
		}
	}, [geolocatedValue?.["data"], positionValue]);

	const [mapState, setMapState] = useState<any>();

	useEffect(() => {
		if (!mapState) {
			return;
		}

		if (!qrValue?.["data"]) {
			return;
		}

		queryValue?.search();
	}, [qrValue?.["data"], mapState]);

	return (
		<MapContainer
			ref={(ref) => {
				if (mapValue) {
					mapValue["ref"].current = ref;
					setMapState(ref);
					// if (_.isUndefined(queryValue?.["query"])) {
					// 	queryValue?.search(ref);
					// }
				}
			}}
			className={styles["map"]}
			center={[lat, lon]}
			zoom={13}
			scrollWheelZoom={true}
			zoomControl={false}
			zoomSnap={0}
			maxZoom={16}
			attributionControl={false}>
			<MapDragListener />

			<TileLayer url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}' />

			{queryValue?.["query"]?.[0].map((store, index) => {
				const qr = qrValue["data"];
				const storeViewData = qr?.["stores"].find(
					(viewData) => viewData["data"]["uuid"] === store["data"]["uuid"]
				)?.["view"];

				let iconUrl = storeViewData?.["pin"]?.["idle"] || idlePin;
				let iconAnchor: [number, number] = [29 / 2, 46];
				let iconSize: [number, number] = [29, 46];

				if (paramsValue?.["params"]["store"] === store["data"].uuid) {
					iconUrl = storeViewData?.["pin"]?.["selected"] || activePin;
					iconAnchor = [29 / 2, 46];
					iconSize = [29, 46];
				}

				return (
					<Marker
						key={index}
						position={latLng(store["data"].coords.lat, store["data"].coords.lon)}
						icon={icon({
							iconUrl: iconUrl,
							iconRetinaUrl: iconUrl,
							iconAnchor: iconAnchor,
							iconSize: iconSize,
						})}
						eventHandlers={{
							click: (e) => {
								triggerAnalytics("STORE:SELECTION", store["data"].uuid);
								paramsValue?.setParams({
									...paramsValue?.["params"],
									store: store["data"].uuid,
								});
							},
						}}
					/>
				);
			})}

			{!!queryValue?.["query"] && (
				<Fragment>
					{!routeValue?.["data"] ? (
						<Circle
							key={queryValue?.["query"].toString()}
							center={queryValue?.["query"][1]}
							pathOptions={{
								color: "rgb(43, 156, 251)",
								fillColor: "rgb(43, 156, 251)",
								opacity:
									qrValue["data"]?.["defaultParams"]?.["search"]?.["view"]?.["ui"] !== false
										? 1
										: 0,
								fillOpacity:
									qrValue["data"]?.["defaultParams"]?.["search"]?.["view"]?.["ui"] !== false
										? 0.025
										: 0,
								weight: 0.5,
							}}
							radius={queryValue?.["query"][2]}
							eventHandlers={{
								add: (e) => {
									if (!mapValue["ref"].current) {
										return;
									}

									if (!e.target) {
										return;
									}

									if (paramsValue?.["params"]["mode"] !== "route") {
										setTimeout(() => {
											mapValue?.["ref"]?.["current"]?.fitBounds(
												e?.["target"]?.getBounds()?.pad(0.1),
												{
													paddingTopLeft: !matches ? [0, 120] : undefined,
													paddingBottomRight: !matches ? [0, 0] : undefined,
												}
											);
										}, 300); // TODO: fix in next release
									}
								},
							}}
						/>
					) : (
						<GeoJSON
							key={JSON.stringify(routeValue?.["data"])}
							data={routeValue?.["data"]}
							eventHandlers={{
								add: (e) => {
									if (!mapValue["ref"].current) return;

									setTimeout(() => {
										mapValue["ref"].current?.fitBounds(e.target.getBounds().pad(0.1), {
											paddingTopLeft: !matches ? [0, 150] : undefined,
											paddingBottomRight: !matches ? [0, 270] : undefined,
										});
									}, 300); // todo: fix in next release
								},
							}}
						/>
					)}
					<Marker
						position={queryValue?.["query"][1]}
						icon={icon({
							iconUrl: centerPin,
							iconRetinaUrl: centerPin,
							iconAnchor: [5, 5],
							popupAnchor: [5, 5],
							iconSize: [10, 10],
						})}
						opacity={
							qrValue["data"]?.["defaultParams"]?.["search"]?.["view"]?.["ui"] !== false ? 1 : 0
						}
					/>
				</Fragment>
			)}

			{!!geolocatedValue?.["data"] && (
				<Marker
					position={[
						geolocatedValue?.["data"].coords.latitude,
						geolocatedValue?.["data"].coords.longitude,
					]}
					icon={icon({
						iconUrl: geolocationPin,
						iconRetinaUrl: geolocationPin,
						iconAnchor: [5, 5],
						popupAnchor: [5, 5],
						iconSize: [10, 10],
					})}
				/>
			)}
		</MapContainer>
	);
};

export { Map };
