import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../ui";
import { typo } from "../../../ui/styles";
import { css } from "../../../ui/utils";
import styles from "./switch.module.scss";

const GPS: FC<{
	disabled?: boolean;
	value?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
}> = ({ value, onChange, disabled }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.checkbox}>
			<div className={css(typo.left, typo.text)}>
				<div>
					<h5 className={css("base", "blue")}>{t("modal_settings_gps_title")}</h5>
				</div>
				<div>
					<p className={css("small", "gray")}>
						{t("modal_settings_gps_description").replace(/&nbsp;/g, "\u00A0")}
					</p>
				</div>
			</div>
			<Checkbox disabled={disabled} checked={value} value={"gps"} onChange={onChange} />
		</div>
	);
};

export { GPS };
