import "./ui/styles/global.scss";
import "./styles/global.scss";
import "./styles/globals.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GeolocationProvider } from "./context/geolocation/geolocation";
import { initReactI18next } from "react-i18next";
import { MapProvider } from "./context/map/map";
import { ModalProvider } from "./context/modals/modals";
import { ParamsProvider } from "./context/params/params";
import { PositionProvider } from "./context/position/position";
import { QrProvider } from "./context/qr/qr";
import { QueriesProvider } from "./context/queries/queries";
import { SessionProvider } from "./context/session/session";
import { ViewPort } from "./components/_viewport/_viewport";
import en from "./translations/en.json";
import ErrorPage from "./pages/error";
import fr from "./translations/fr.json";
import i18n from "i18next";
import it from "./translations/it.json";
import LanguageDetector from "i18next-browser-languagedetector";
import ReactDOM from "react-dom/client";
import { RoutingProvider } from "./context/routing/routing";
import { AnalyticsLayer } from "./layers/analytics/analytics";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const resources = {
	en: { translation: en },
	fr: { translation: fr },
	it: { translation: it },
};

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		detection: {
			order: ["localStorage", "navigator"],
			lookupLocalStorage: "language",
			caches: ["localStorage"],
		},
		resources,
		interpolation: {
			escapeValue: false,
		},
		fallbackLng: "en",
	});

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<SessionProvider>
				<GeolocationProvider>
					<QrProvider>
						<ParamsProvider>
							<ModalProvider>
								<PositionProvider>
									<MapProvider>
										<QueriesProvider>
											<AnalyticsLayer>
												<RoutingProvider>
													{/* /// */}
													<ViewPort />
													{/* /// */}
												</RoutingProvider>
											</AnalyticsLayer>
										</QueriesProvider>
									</MapProvider>
								</PositionProvider>
							</ModalProvider>
						</ParamsProvider>
					</QrProvider>
				</GeolocationProvider>
			</SessionProvider>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/:qrUuid",
			},
		],
	},
]);

root.render(<RouterProvider router={router} />);
