import { FC, FormEventHandler, useContext, useState } from "react";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/components/Button/button";
import { TextInput } from "../../ui/components/TextInput/textInput";
import { css } from "../../ui/utils";
import { typo } from "../../ui/styles";
import { TextArea } from "../../ui/components/TextArea/textArea";
import axios from "axios";

const Contact: FC<ModalProps> = ({ onClose }) => {
	const [value, setValue] = useState({
		name: "",
		email: "",
		message: "",
	});

	const [message, setMessage] = useState<string>();
	const [error, setError] = useState<string>();

	const { t } = useTranslation();

	const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();

		// axios
		// 	.post(
		// 		`${process.env.REACT_APP_API_HOST}/contact`,
		// 		{ ...value, sessionUuid: settings.sessionId },
		// 		{
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 				Authorization: `code ${settings.previewCode}`,
		// 			},
		// 		}
		// 	)
		// 	.then((response) => {
		// 		setMessage(t("success") || undefined);
		// 		setError(undefined);
		// 		setValue({
		// 			name: "",
		// 			email: "",
		// 			message: "",
		// 		});
		// 	})
		// 	.catch((e) => {
		// 		setMessage(undefined);
		// 		setError(t("error") || undefined);
		// 	});
	};

	return (
		<Modal align={"bottom"} onClose={onClose}>
			<form onSubmit={handleSubmit}>
				<h1 className={typo.center}>{t("modal_contacts_title")}</h1>
				<p className={typo.center}>{t("modal_contacts_message")}</p>

				<section style={{ margin: "1rem 0" }}>
					<p className={css(typo.left, typo.small, typo.gray)}>{t("modal_contacts_name_flag")}</p>
					<TextInput
						value={value.name}
						onChange={(e) => setValue({ ...value, name: e.target.value })}
						required
					/>
				</section>

				<section style={{ margin: "1rem 0" }}>
					<p className={css(typo.left, typo.small, typo.gray)}>{t("modal_contacts_email_flag")}</p>
					<TextInput
						type='email'
						value={value.email}
						onChange={(e) => setValue({ ...value, email: e.target.value })}
						required
					/>
				</section>

				<section style={{ margin: "1rem 0" }}>
					<p className={css(typo.left, typo.small, typo.gray)}>
						{t("modal_contacts_message_flag")}
					</p>
					<TextArea
						value={value.message}
						onChange={(e) => setValue({ ...value, message: e.target.value })}
						required
					/>
				</section>

				<section style={{ marginTop: "1.5rem" }} className={typo.center}>
					<Button type='submit'>{t("modal_contacts_submit")}</Button>
				</section>

				{error && (
					<section style={{ marginTop: "1rem" }} className={css(typo.center, typo.red, typo.text)}>
						<p>{error}</p>
					</section>
				)}

				{message && (
					<section style={{ marginTop: "1rem" }} className={css(typo.center, typo.text)}>
						<p>{message}</p>
					</section>
				)}
			</form>
		</Modal>
	);
};

export { Contact };
