import _ from "lodash";
import { Accordion } from "../../ui/components/Accordion/accordion";
import { Button } from "../../ui/components/Button/button";
import { css } from "../../ui/utils";
import { FC, Fragment, useContext } from "react";
import { geolocationContext } from "../../context/geolocation/geolocation";
import { GPSSwitch } from "../input/switch";
import { icon } from "../../ui/styles";
import { LanguageSelect } from "../input/select";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { modalsContext } from "../../context/modals/modals";
import { Paper } from "../../ui/components/Paper/paper";
import { ReactComponent as IconCookies } from "./../../assets/cookies.svg";
import { ReactComponent as IconLegals } from "./../../assets/legals.svg";
import { ReactComponent as IconMail } from "./../../assets/contact-us.svg";
import { ReactComponent as IconOkeanYs } from "./../../assets/logo-extended.svg";
import { ReactComponent as IconOkeanYsSmall } from "./../../assets/okeanYs.svg";
import { TextInput } from "../../ui/components/TextInput/textInput";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import styles from "./settings.module.scss";

const Settings: FC<ModalProps> = (props) => {
	const [, setIsVisible] = useContext(modalsContext);
	const [previewCode, setPreviewCode] = useLocalStorage("previewCode", "");

	const { data, isLoading, isError, start, stop } = useContext(geolocationContext);

	const { t, i18n } = useTranslation();

	return (
		<Fragment>
			<Modal {...props} className={"center"}>
				<IconOkeanYs className={css(icon.block, icon.hbig)} />

				<h1>{t("modal_settings_title")}</h1>

				<LanguageSelect
					value={_.first(i18n.language.split("-"))}
					onChange={(v) => i18n.changeLanguage(String(v))}
				/>

				<GPSSwitch
					disabled={!!isLoading}
					value={!isLoading && !isError && !!data}
					onChange={({ target: { checked } }) => (checked ? start() : stop())}
				/>

				<Accordion
					className='left'
					heading={
						<h5>
							<IconLegals className={css(icon.inline, icon.normal, icon.spaced)} />
							<strong>
								<mark>{t("modal_settings_legals_title")}</mark>
							</strong>
						</h5>
					}>
					<div
						className='small'
						dangerouslySetInnerHTML={{ __html: t("modal_settings_legals_content") || "" }}
					/>
				</Accordion>

				<Accordion
					className='left'
					heading={
						<h5>
							<IconCookies className={css(icon.inline, icon.normal, icon.spaced)} />
							<strong>
								<mark>{t("modal_settings_cookies_title")}</mark>
							</strong>
						</h5>
					}>
					<div
						className='small'
						dangerouslySetInnerHTML={{ __html: t("modal_settings_cookies_content") || "" }}
					/>
				</Accordion>

				<Accordion
					className='left'
					heading={
						<h5>
							<IconOkeanYsSmall className={css(icon.inline, icon.normal, icon.spaced)} />
							<strong>
								<mark>{t("modal_settings_about_title")}</mark>
							</strong>
						</h5>
					}>
					<div
						className='small'
						dangerouslySetInnerHTML={{ __html: t("modal_settings_about_content") || "" }}
					/>
				</Accordion>

				<p>
					<a
						className='red small'
						onClick={() => {
							const result = window.confirm(t("modal_settings_erase_confirm") || "");
							if (!!result) {
								window.localStorage.clear();
								window.location.href = "/";
							}
						}}>
						{t("modal_settings_erase_title")}
					</a>
				</p>

				<p>
					<Button className='base' variant='alert' onClick={() => setIsVisible("contacts")}>
						<IconMail className={css(icon.inline, icon.normal, icon.spaced)} />
						{t("modal_contacts_title")}
					</Button>
				</p>

				<Paper className={css(styles["preview-code"], "left", "small", "gray")}>
					<div>
						<p className={css()}>{t("modal_settings_preview-code_title")}</p>
					</div>
					<TextInput
						className={styles["preview-code-input"]}
						value={previewCode}
						onChange={({ target: { value } }) => setPreviewCode(value)}
						placeholder={t("modal_settings_preview-code_placeholder") || undefined}
					/>
				</Paper>
			</Modal>
		</Fragment>
	);
};

export { Settings };
