import { css } from "../../ui/utils";
import { FC, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { LocationTextInput } from "../input/textInput";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { Paper } from "../../ui/components/Paper/paper";
import { qrContext } from "../../context/qr/qr";
import { useTranslation } from "react-i18next";
import styles from "./../disclaimers/disclaimer.module.scss";
import styles1 from "./noResult.module.scss";

const NoResults: FC<ModalProps> = ({ onClose }) => {
	const { data: qr } = useContext(qrContext);

	const { t } = useTranslation();

	const disclaimerContent = t("modal_no-results_message");
	const ecommerce = qr?.["item"]?.["ecommerce"];

	return (
		<Modal align={"bottom"} onClose={onClose} className={"center"}>
			<h1>{t("modal_no-results_title")}</h1>

			{disclaimerContent && (
				<Paper
					className={css(
						styles["disclaimer"],
						styles["disclaimer--alert"],
						"center",
						"small",
						"gray"
					)}
					dangerouslySetInnerHTML={{
						__html: disclaimerContent,
					}}
				/>
			)}

			<LocationTextInput className={styles1["location"]} />

			{ecommerce && (
				<Fragment>
					<mark>- {t("modal_no-results_separator")} -</mark>

					<ul className={styles1["referrals"]}>
						<Link to={ecommerce}>
							<li>
								<Paper className={css("center", styles1["referral"])}>
									{t("modal_no-results_referral-ecommerce")}
								</Paper>
							</li>
						</Link>
					</ul>
				</Fragment>
			)}
		</Modal>
	);
};

export { NoResults };
