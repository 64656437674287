import { css } from "../../ui/utils";
import { FC, useContext } from "react";
import { icon } from "../../ui/styles";
import { Link } from "react-router-dom";
import { Modal, ModalProps } from "../../ui/components/Modal/modal";
import { modalsContext } from "../../context/modals/modals";
import { Paper } from "../../ui/components/Paper/paper";
import { ReactComponent as IconOkeanYs } from "./../../assets/logo-extended.svg";
import { useLocalStorage } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import styles from "./../disclaimers/disclaimer.module.scss";

const Target: FC<ModalProps> = (props) => {
	const [, setIsVisible] = useContext(modalsContext);
	const [history] = useLocalStorage<string[]>("history", []);

	const { t } = useTranslation();

	const disclaimerContent = !!history["length"]
		? t("modal_target_history")
		: t("modal_target_history-empty");

	return (
		<Modal
			{...props}
			className={css("center")}
			onClose={() => setIsVisible("settings")}
			onCloseContent={<p className={css("gray", "small")}>{t("modal_target_action")}</p>}>
			<IconOkeanYs className={css(icon.block, icon.hbig)} />

			<h1>{t("modal_target_title")}</h1>

			{disclaimerContent && (
				<Paper
					className={css(
						styles["disclaimer"],
						styles["disclaimer--alert"],
						"center",
						"small",
						"gray"
					)}
					dangerouslySetInnerHTML={{
						__html: disclaimerContent,
					}}
				/>
			)}

			<p className={css("base", "blue", "small")}>
				<Link to={"https://okeanys.com"}>{t("modal_target_about-link")}</Link>
			</p>
		</Modal>
	);
};

export { Target };
