import { Fragment, useMemo } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { css } from "../ui/utils";
import styles from "./../styles/error.module.scss";
import { ReactComponent as Logo } from "./../assets/logo-extended.svg";
import createHttpError from "http-errors";

export default function ErrorPage() {
	const error = useRouteError();

	const httpError = useMemo(() => {
		if (isRouteErrorResponse(error)) {
			return createHttpError(error.status);
		}

		return createHttpError(500);
	}, [error]);

	return (
		<Fragment>
			<style>{`
			html {
				background-color: rgb(43, 156, 251);
				color: rgb(255,255,255)
			}

			#root {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			`}</style>
			<main className={css(styles["main"])}>
				<Logo className={css(styles["logo"])} />

				<h1 className={styles["h1"]}>{httpError.status}</h1>
				<p className={styles["p"]}>{httpError.message}</p>
			</main>
		</Fragment>
	);
}
