import { css } from "../../ui/utils";
import { CSSProperties, FC, MouseEventHandler } from "react";
import { Paper } from "../../ui/components/Paper/paper";
import { ReactComponent as IconClose } from "./../../assets/close.svg";
import styles from "./logo.module.scss";

const Landscape: FC<{
	className?: string;
	uuid?: string;
	logo?: string;
	style?: CSSProperties;
	onClick?: MouseEventHandler<HTMLDivElement>;
	onClose?: MouseEventHandler<HTMLDivElement>;
}> = ({ className, logo, onClick, onClose, ...rest }) => {
	return (
		<Paper className={css(styles.container, className)} {...rest}>
			<div>
				<div>{logo && <img src={logo} onClick={onClick} />}</div>
			</div>
			{onClose && (
				<div className={styles.close} onClick={onClose}>
					<IconClose />
				</div>
			)}
		</Paper>
	);
};

export { Landscape as LogoLandscape };
