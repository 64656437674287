import { DetailedHTMLProps, FC, PropsWithChildren } from "react";
import { css } from "../../utils";
import styles from "./paper.module.scss";

type DefaultProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface PaperProps extends DefaultProps, PropsWithChildren {}

const Paper: FC<PaperProps> = ({ children, className, ...rest }) => {
	return (
		<div className={css(styles.container, className)} {...rest}>
			{children}
		</div>
	);
};

export { Paper };
